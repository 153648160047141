.pg-button {
  -webkit-appearance: none;
  height: 40px;
  border-radius: 24px;
  text-align: center;
  padding: 15px 40px;
  margin-top: 5px;
  background-color: #2f5de4;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  transition: all 0.3s ease;
  outline: 0;
  display: flex;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  align-items: center;
  cursor: pointer;

  &:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0 2px 6px -1px rgb(182, 157, 230);

    &:active {
      transform: scale(0.99);
    }
  }
}

.pg-outline-button {
  height: 40px;
  border-radius: 24px;
  text-align: center;
  padding: 15px 40px;
  margin-top: 5px;
  border-color: #2f5de4;
  border-width: 2px;
  color: #2f5de4;
  background-color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  align-items: center;
  cursor: pointer;

  &:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0 2px 6px -1px mix(#2f5de4, #fff, 30%);

    &:active {
      transform: scale(0.99);
    }
  }
}

.invert-button {
  color: #2f5de4;
  border: 1px solid #2f5de4;
  background-color: white;
  border-style: solid;
  display: flex;
  align-items: center;
  font-weight: 600;
}


.statusAPPROVED {
  font-weight: 700;
  line-height: 18.9px;
  padding: 4px;
  border-radius: 4px;
  color: white;
  background-color: #77d94a;
}

.statusREJECTED {
  font-weight: 700;
  line-height: 18.9px;
  padding: 4px;
  border-radius: 4px;
  color: white;
  background-color: #ca3434;
}

.statusWAITING {
  font-weight: 700;
  line-height: 18.9px;
  padding: 4px;
  border-radius: 4px;
  color: white;
  background-color: #f3a647;
}

.statusSUCCESS {
  font-weight: 700;
  line-height: 18.9px;
  color: #77d94a;
}

.statusFAILED {
  font-weight: 700;
  line-height: 18.9px;
  color: #ca3434;
}

.statusPENDING {
  font-weight: 700;
  line-height: 18.9px;
  color: #f3a647;
}

.statusnull {
  font-weight: 700;
  font-size: 13px;
  color: #adadad;
}

.disabled-element {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}