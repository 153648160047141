@use "@angular/material" as mat;
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import url("https://fonts.cdnfonts.com/css/open-sauce-one");

* {
  font-family: "Open Sauce One", sans-serif;
}

body {
  background-color: white;
  margin: 0;
  font-family: "Open Sauce One", sans-serif;
}

.col {
  display: flex;
  flex-direction: column;
}

.capitalize {
  text-transform: capitalize;
}

.title-case {
  text-transform: uppercase;
}

.row {
  display: flex;
  flex-direction: row;
}

.align-baseline {
  align-items: baseline;
}

.icon {
  &.union {
    content: url("assets/icon/union.svg");
  }

  &.fastforward-clock-time {
    content: url("assets/icon/fastforward-clock-time.svg");
  }

  &.pending {
    content: url("assets/icon/pending.svg");
  }

  &.task-checkmark {
    content: url("assets/icon/task-check-mark.svg");
  }

  &.mark {
    content: url("assets/icon/mark.svg");
  }

  &.alert {
    content: url("assets/icon/alert.svg");
  }

  &.office-worker {
    content: url("assets/icon/office-worker.svg");
  }

  &.block-add {
    content: url("assets/icon/block-add.svg");
  }

  &.progress-spinner {
    content: url("assets/icon/progress-spinner.svg");
  }

  &.credit-card {
    content: url("assets/icon/credit-card.svg");
  }

  &.download {
    content: url("assets/icon/download.svg");
  }

  &.add-circle {
    content: url("assets/icon/add.svg");
  }

  &.pencil {
    content: url("assets/icon/edit.svg");
  }

  &.trash {
    content: url("assets/icon/trash.svg");
  }

  &.eye {
    content: url("assets/icon/eye.svg");
  }

  &.sort-up {
    content: url("assets/icon/sort-up.svg");
  }

  &.sort-down {
    content: url("assets/icon/sort-down.svg");
  }

  &.copy {
    content: url("assets/icon/copy.svg");
  }

  &.copy-blue {
    content: url("assets/icon/copy-blue.svg");
  }

  &.developer-setting {
    content: url("assets/icon/developer-setting.svg");
  }

  &.filter {
    content: url("assets/icon/filter.svg");
  }

  &.circle-check {
    content: url("assets/icon/circle-check.svg");
  }

  &.send {
    content: url("assets/icon/send.svg");
  }

  &.transaction-history {
    content: url("assets/icon/transaction-history.svg");
  }

  &.caution {
    content: url("assets/icon/caution.svg");
  }

  &.caution-invert {
    content: url("assets/icon/caution-invert.svg");
  }

  &.check-circle {
    content: url("assets/icon/check-circle.svg");
  }

  &.close-circle {
    content: url("assets/icon/rejected-circle.svg");
  }

  &.services {
    content: url("assets/icon/services.svg");
  }

  &.coin {
    content: url("assets/icon/coin.svg");
  }
}


.headline-medium {

  font-size: 20px;
  font-weight: 700;
  line-height: 27px;

}

.headline-small {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.3px;
}

.headline-large {
  //styleName: Headline/Headline Large;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.4px;

}

.body-large {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
}

.body-regular {

  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;

}


.heading-large {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
}

.heading-small {
  //styleName: Heading/Heading Small;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.55px;

}

.heading-medium {
  font-size: 15px;
  font-weight: 700;
  line-height: 20.25px;

}

.heading-regular {

  font-size: 14px;
  font-weight: 700;
  line-height: 18.9px;

}

.error-message {
  color: rgba(202, 52, 52, 1);
  font-size: 12px;
  font-weight: 400;


}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::ng-deep .global-tooltip {
  .p-tooltip-arrow {
    border-bottom-color: white;
  }

  .p-tooltip-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.2px;
    background-color: white;
    color: black;
  }
}